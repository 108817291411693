<!--
 * @Author: lsw lsw_0524@163.com
 * @Date: 2024-03-12 15:03:20
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2024-03-13 09:44:30
 * @FilePath: /yiyun_project/src/views/shortVideo/vipCard.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main>
    <div class="btnBox">
      <el-button size="mini" type="primary" @click="openVipPackage('add')">+ 添加vip套餐</el-button>
    </div>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="vip类型：">
        <el-select size="small" v-model="card_list.type" placeholder="请选择">
          <el-option label="全部" :value="0"></el-option>
          <el-option v-for="item in type_list" :key="item.type" :label="item.name" :value="item.type"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否启用：">
        <el-select size="small" v-model="card_list.is_show" placeholder="请选择">
          <el-option label="全部" :value="-1"></el-option>
          <el-option label="未启用" :value="0"></el-option>
          <el-option label="已启用" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="21px">
        <el-button size="small" type="primary" @click="getCardList(1)">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="card_list.list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
      <el-table-column label="类型" width="100" align="center">
        <template v-slot="{ row }">{{ dealTypeName(row) }}</template>
      </el-table-column>
      <!-- <el-table-column prop="card_desc" label="标题" width="100" align="center"></el-table-column> -->
      <el-table-column prop="card_desc" label="描述" align="center"></el-table-column>
      <el-table-column prop="day_price" label="价格" width="100" align="center"></el-table-column>
      <el-table-column prop="month_price" label="划线价格" width="100" align="center"></el-table-column>
      <el-table-column label="是否启用" width="140" align="center">
        <template v-slot="{ row }">
          <el-switch disabled v-model="row.is_show" :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{ row }">
          {{ getDateformat(row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button size="mini" type="warning" @click="openVipPackage('edit', row)" icon="el-icon-edit-outline">编辑</el-button>
          <el-button size="mini" type="danger" @click="deletePackage(row)" icon="el-icon-delete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="card_list.total" :page="card_list.page" :pageNum="card_list.rows" @updatePageNum="updateData"></Paging>
    <!-- vip套餐弹窗 -->
    <el-dialog :title="mode == 'add' ? '添加' : '编辑'" :visible.sync="is_vip_dialog">
      <el-form>
        <el-form-item label="类型" label-width="120px">
          <el-select v-model="controls_data.type" placeholder="请选择">
            <el-option v-for="item in type_list" :key="item.type" :label="item.name" :value="item.type"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="描述" label-width="120px">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="controls_data.card_desc"></el-input>
        </el-form-item>
        <el-form-item label="价格" label-width="120px">
          <el-input type="number" placeholder="请输入价格" v-model="controls_data.day_price"></el-input>
        </el-form-item>
        <el-form-item label="划线价格" label-width="120px">
          <el-input type="number" placeholder="请输入价格" v-model="controls_data.month_price"></el-input>
        </el-form-item>
        <el-form-item label="是否启用" label-width="120px">
          <el-switch v-model="controls_data.is_show" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="is_vip_dialog = false">取 消</el-button>
        <el-button type="primary" @click="sureContorls">确 定</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      card_list: {
        page: 1,
        rows: 10,
        total: 0,
        list: [],
        type: 0,
        is_show: -1,
      },
      // 类型 1--日卡 2--月卡 3--季卡 4--年卡
      type_list: [
        { type: 1, name: '日卡' },
        { type: 2, name: '月卡' },
        { type: 3, name: '季卡' },
        { type: 4, name: '年卡' },
      ],
      is_vip_dialog: false,
      mode: 'add',
      controls_data: {
        type: '',
        is_show: 1,
        day_price: '',
        month_price: '',
        card_desc: '',
      },
    };
  },
  created() {
    this.getCardList();
  },
  methods: {
    clearSearch() {
      this.card_list = {
        page: 1,
        rows: 10,
        total: 0,
        list: [],
        type: 0,
        is_show: -1,
      };
      this.getCardList();
    },
    dealTypeName(data) {
      let type_info = this.type_list.find(i => i.type == data.type);
      if (!type_info) return '--';
      return type_info.name;
    },
    updateData(val, status) {
      if (status == 0) {
        this.card_list.rows = val;
      } else {
        this.card_list.page = val;
      }
      this.getCardList();
    },
    getCardList(style) {
      if (style) {
        this.card_list.page = 1;
      }
      let obj = {
        page: this.card_list.page,
        rows: this.card_list.rows,
      };
      this.$axios.post(this.$api.shortVideo.vipCardList, obj).then(res => {
        if (res.code == 0) {
          this.card_list.list = res.result.list;
          this.card_list.total = res.result.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    openVipPackage(mode, data) {
      this.mode = mode;
      if (data) {
        this.controls_data = JSON.parse(JSON.stringify(data));
      } else {
        this.controls_data = {
          type: '',
          is_show: 1,
          day_price: '',
          month_price: '',
          card_desc: '',
        };
      }
      this.is_vip_dialog = true;
    },
    sureContorls() {
      let path = this.$api.shortVideo.vipAdd;
      let obj = {
        type: '',
        is_show: this.controls_data.is_show,
        day_price: '',
        month_price: '',
        card_desc: '',
      };
      let flag = false;
      for (const key in obj) {
        if (key !== 'is_show' && !this.controls_data[key]) {
          flag = true;
          break;
        } else {
          obj[key] = this.controls_data[key];
        }
      }
      if (flag) {
        this.$message.warning('请将数据填写完整');
      }
      if (this.mode == 'edit') {
        obj.id = this.controls_data['id'];
        path = this.$api.shortVideo.vipEdit;
      }
      this.$axios.post(path, obj).then(res => {
        if (res.code == 0) {
          let str = '';
          if (this.mode == 'add') str = '添加成功';
          if (this.mode == 'edit') str = '编辑成功';
          this.$message.success(str);
          this.is_vip_dialog = false;
          this.getCardList(1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    deletePackage(data) {
      this.$confirm('此操作将删除该配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.shortVideo.vipdelete, {
            id: data.id,
          });
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getCardList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  .btnBox {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
}
</style>
